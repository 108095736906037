import { ErpTableOptions } from '@/types/type';
import { showFiles } from '@/utils/publicMethods';

import { computed, defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();
  const status = [
    {
      value: 0,
      label: '无效',
    },
    {
      value: 1,
      label: '有效',
    },
  ];
  const booleanStatus = [
    {
      value: true,
      label: '是',
    },
    {
      value: false,
      label: '否',
    },
  ];
  const confirmStatus = [
    {
      value: 1,
      label: '待确认',
    },
    {
      value: 2,
      label: '已确认',
    },
  ];

  const columnList: ErpTableOptions[] = [
    {
      label: '结算单号',
      prop: 'sellStmtNo',
      value: '',
      minWidth: 128,
      type: 'input',
    },
    {
      minWidth: 160,
      label: '销售合同号',
      prop: 'businessContractNo', //

      value: '',
      type: 'input',
    },
    {
      minWidth: 183,
      label: '客户',
      prop: 'customerName',

      value: '',
      type: 'input',
    },
    {
      minWidth: 183,
      label: '签约主体',
      prop: 'contractPrincipalName',
      value: '',
      type: 'input',
    },
    {
      minWidth: 128,
      label: '提货件数',
      prop: 'pickupPackageCount',
      propDesc: 'pickupPackageCountDesc',
      value: [],
      type: 'number',
      filterProp: ['pickupPackageMinCount', 'pickupPackageMaxCount'],
    },
    {
      minWidth: 170,
      label: '本次提货款金额',
      prop: 'pickupAmount',
      propDesc: 'pickupAmountDesc',
      value: [],
      type: 'number',
      filterProp: ['pickupMinAmount', 'pickupMaxAmount'],
    },
    {
      minWidth: 157,
      label: '实际收款金额',
      prop: 'actualInfundAmount',
      propDesc: 'actualInfundAmountDesc',
      value: [],
      type: 'number',
      filterProp: ['actualInfundMinAmount', 'actualInfundMaxAmount'],
    },
    {
      minWidth: 128,
      label: '结算来源',
      prop: 'sources',
      propDesc: 'sourceDesc',
      value: [],
      type: 'multiSelect',
      options: 'sell_stmt_source',
    },
    {
      minWidth: 128,
      label: '结算日期',
      prop: 'settleDate',
      value: [],
      type: 'time',
      filterProp: ['settleStartDate', 'settleEndDate'],
    },
    {
      minWidth: 128,
      label: '业务名称',
      prop: 'productType',
      propDesc: 'productTypeDesc',
      value: [],
      type: 'multiSelect',
      options: 'product_category',
    },
    {
      label: '计费方式',
      prop: 'settleType',
      propDesc: 'settleTypeDesc',
      minWidth: 140,
      value: [],
      type: 'multiSelect',
      options: 'settle_type',
    },
    {
      minWidth: 100,
      label: '状态',
      prop: 'isValid',
      propDesc: 'validDesc',
      value: 1,
      type: 'signSelect',
      options: status,
    },
    {
      minWidth: 114,
      label: '更新人',
      prop: 'lastOperator',
      value: '',
      type: 'input',
    },
    {
      minWidth: 160,
      label: '更新时间',
      prop: 'updateTime',
      value: [],
      type: 'time',
      filterProp: ['updateStartTime', 'updateEndTime'],
    },
    {
      minWidth: 140,
      label: '结算单上传人',
      prop: 'uploadUser',
      value: '',
      type: 'input',
    },
    {
      minWidth: 170,
      label: '结算单上传时间',
      prop: 'uploadTime',
      value: [],
      type: 'time',
      filterProp: ['uploadTimeStartTime', 'uploadTimeEndTime'],
    },
    {
      minWidth: 128,
      label: '确认人',
      prop: 'confirmOperator',
      value: '',
      type: 'input',
    },
    {
      minWidth: 128,
      label: '确认结果',
      prop: 'confirmStatus',
      propDesc: 'confirmStatusDesc',
      value: '',
      type: 'signSelect',
      options: confirmStatus,
    },
    {
      minWidth: 170,
      label: '确认时间',
      prop: 'confirmTime',

      value: [],
      type: 'time',
      filterProp: ['confirmTimeStartTime', 'confirmTimeEndTime'],
    },
    {
      label: '确认说明',
      prop: 'confirmDesc',
      minWidth: 120,
      value: '',
      type: 'input',
      isSort: false,
    },
    {
      label: '是否需要客户电子签章',
      prop: 'isNeedCustomerSign',
      propDesc: 'isNeedCustomerSignDesc',
      minWidth: 216,
      value: '',
      type: 'signSelect',
      options: booleanStatus,
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 280,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button plain type="primary"
        v-if="(row.confirmStatusDesc  === '待确认' || row.confirmStatus === null) && (row.isValid && !isHide) && hasPermission('erp:fmSellStmt:updateFmSellStmtList') "
        @click="confirmFn(row)">确认</el-button>
      <el-button plain type="primary" v-if="hasPermission('erp:fmSellStmt:download')" @click='onlineView(row)'>查看</el-button>
      <el-button plain type="primary" v-if="row.isValid && hasPermission('erp:fmSellStmt:download')" @click='downLoad(row)'>下载</el-button>
      <el-popover placement="bottom-start" width="120" popper-class="indical-popover" v-if="row.isValid && !isHide" trigger="click">
        <div class="operate-indical" v-if="row.sourceDesc === '杂费结算' || row.sourceDesc ==='提空后结算' ">
          <div @click="toVoidFn(row)">
            <span class="icon maliiconfont maliicon-img_details_empty"></span>
            <span>作废</span>
          </div>
        </div>
        <div class="operate-indical" v-if="row.sourceDesc !== '杂费结算' && hasPermission('erp:fmSellStmt:updateFmSellStmtList')"
        @click="updateFn(row)"
        >
          <div >
            <span class="icon maliiconfont maliicon-1"></span>
            <span>更新</span>
          </div>
        </div>
        <div class="operate-indical" v-if="row.sourceDesc === '杂费结算' && hasPermission('erp:fmSellStmt:updateFmSellStmtList')" @click="editFn(row)">
          <div >
            <span class="icon maliiconfont maliicon-6"></span>
            <span>编辑</span>
          </div>
        </div>
        <div class="operate-indical"  @click="uploadFn(row)">
          <div>
            <span class="icon maliiconfont maliicon-img_details_download1"></span>
            <span>上传结算单</span>
          </div>
        </div>
        <template #reference>
          <div class="bottom-dot"></div>
        </template>
    
      </el-popover>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup(props) {
          const isHide = computed(() => {
            return props.row.orderStatus === 2 || props.row.orderStatus === 3;
          });
          const updateFn = (row) => {
            Instance.setupState.updateFn(row);
          };
          const editFn = (row) => {
            Instance.setupState.editFn(row);
          };
          // 查看
          const onlineView = (row) => {
            showFiles(row.excelName, row.excelUrl);
          };
          // 下载
          const downLoad = (row) => {
            if (row.excelUrl.indexOf('.pdf') > -1) {
              const strFileName = row.excelName.replace(/(.*\/)*([^.]+).*/gi, '$2');
              showFiles(strFileName + '.pdf', row.excelUrl, false);
            } else {
              showFiles(row.excelName, row.excelUrl, false);
            }
          };
          const toVoidFn = (row) => {
            Instance.setupState.toVoid(row);
          };
          const uploadFn = (row) => {
            Instance.setupState.uploadFn(row);
          };
          const confirmFn = (row) => {
            Instance.setupState.confirmFn(row);
          };

          return {
            confirmFn,
            uploadFn,
            toVoidFn,
            downLoad,
            onlineView,
            updateFn,
            editFn,
            isHide,
          };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
