<template>
  <div class="settle-form-dialog">
    <div class="settle-tips">
      <span
        class="icon maliiconfont maliicon-img_details_report1 indical"
      ></span>
      <span class="tips-text"
        >杂费结算用于结算与客户之间某一项或几项指定的应收项目，例如仓储费、滞港费。</span
      >
    </div>
    <el-form
      ref="form"
      :model="formData"
      label-width="140px"
      :rules="rules"
      class="settle-dialog-form"
    >
      <el-form-item label="销售合同号" prop="businessContractNo">
        <el-input
          v-model="formData.businessContractNo"
          clearable
          :disabled="Boolean(row.id)"
          :maxlength="50"
          style="width: 396px"
          class="mali-full__inputcom"
          placeholder="请填写销售合同号"
          @blur="getMixedTypeDtosOption"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="需结算的应收项目"
        prop="mixedTypeDtos"
        :rules="{
          type: 'array',
          required: requiredMixedTypeDtos,
          message: '请选择需结算的应收项目',
          trigger: 'change',
        }"
      >
        <el-select
          v-model="formData.mixedTypeDtos"
          :disabled="!requiredMixedTypeDtos"
          clearable
          filterable
          multiple
          collapse-tags
          :multiple-limit="20"
          style="width: 396px"
          value-key="id"
          placeholder="请选择需结算的应收项目"
          class="incidal-select"
          @change="getMixedTypeDtos"
        >
          <el-option
            v-for="item in mixedTypeDtosList"
            :key="item.id"
            :label="item.feeTypeDisplay + '  ' + item.amountRmb"
            :value="item"
          >
            <span>{{ item.feeTypeDisplay }} {{ item.amountRmb }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="btn-box">
      <el-button size="small" type="primary" @click="submit">{{
        btnTitle
      }}</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['btnTitle', 'row'],
  data() {
    return {
      formData: {
        id: null,
        businessContractNo: '',
        mixedTypeDtos: '',
      },
      filterFormField: [],
      mixedTypeDtosList: [],
      rules: {
        businessContractNo: [
          {
            type: 'string',
            required: true,
            message: '请填写销售合同号',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    requiredMixedTypeDtos() {
      return this.formData.businessContractNo;
    },
  },
  watch: {
    'formData.businessContractNo'() {
      if (!this.formData.businessContractNo) {
        this.formData.mixedTypeDtos = [];
        this.mixedTypeDtosList = [];
        this.$refs.form.clearValidate('mixedTypeDtos');
      }
    },
  },
  created() {
    if (this.row.id) {
      this.getInitData(this.row.id);
    }
  },
  methods: {
    getMixedTypeDtos(e) {
      this.filterFormField = e.map((item) => {
        return {
          id: item.id,
          feeTypeDisplay: item.feeTypeDisplay,
          feeType: item.feeType,
          amountRmb: item.amountRmb,
          amountRmbDisplay: item.amountRmbDisplay,
        };
      });
      if (e.length === 0) {
        this.filterFormField = [];
      }
    },
    getInitData(id) {
      this.ajax({
        type: 'GET',
        url: '/malicrm/fmSellStmt/queryMixedStmt/' + id,
        success: (res) => {
          if (res.code === 200 || res.data.code === 200) {
            const result = res.result ? res.result : res.data.result;
            this.formData.businessContractNo = result.businessContractNo;
            this.getMixedTypeDtosOption();
            this.formData.id = result.sellStmtId;
            this.formData.mixedTypeDtos = result.sellStmtDetailVos.map(
              (item) => {
                return {
                  id: item.id,
                  feeTypeDisplay: item.feeTypeDisplay,
                  feeType: item.feeType,
                  amountRmb: item.amountRmb,
                  amountRmbDisplay: item.amountRmbDisplay,
                };
              }
            );
          } else {
            this.formData.mixedTypeDtos = [];
            this.mixedTypeDtosList = [];
            this.errorTip(res.message);
          }
        },
      });
    },
    getMixedTypeDtosOption() {
      if (!this.formData.businessContractNo) {
        this.formData.mixedTypeDtos = [];
        this.mixedTypeDtosList = [];
        return;
      }
      this.ajax({
        type: 'GET',
        url:
          '/malicrm/fmSellStmt/getOrderArList/' +
          this.formData.businessContractNo,
        success: (res) => {
          if (res.code === 200 || res.data.code === 200) {
            const result = res.result ? res.result : res.data.result;
            this.mixedTypeDtosList = result.map((item) => {
              return {
                id: item.id,
                feeTypeDisplay: item.feeTypeDisplay,
                feeType: item.feeType,
                amountRmb: item.amountRmb,
                amountRmbDisplay: item.amountRmbDisplay,
              };
            });
          } else {
            this.formData.mixedTypeDtos = [];
            this.mixedTypeDtosList = [];
            this.errorTip(res.message);
          }
        },
      });
    },
    submit() {
      // 提交动作
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveSubmitForm();
        }
      });
    },
    saveSubmitForm() {
      const businessContractNo = this.formData.businessContractNo;
      const mixedTypeDtos = this.formData.mixedTypeDtos;
      const id = this.formData.id;
      this.ajax({
        type: 'POST',
        url: '/malicrm/fmSellStmt/setMixedStmt',
        data: { businessContractNo, mixedTypeDtos, id },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.successTip('已生成结算单');
            this.$emit('cancel', true); // 通知列表页面关闭弹窗    true刷新列表  false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.settle-form-dialog {
  padding: 0 15px 30px 15px;
  .mali-full__inputcom {
    color: #1a1a1a;
  }
  .settle-tips {
    display: flex;
    // margin-top: 15px;
    justify-content: flex-start;
    margin-bottom: 35px;
    align-items: center;
    .icon {
      flex-basis: 32px;
      font-size: 18px;
      color: #c7c8cd;
    }
    .tips-text {
      line-height: 24px;
      margin-top: -2px;
      color: #909399;
      font-size: 14px;
      font-family: '微软雅黑';
    }
    .indical {
      flex-basis: 27px;
    }
  }
  .btn-box {
    text-align: center;
    margin-top: 50px;
  }
}
</style>
