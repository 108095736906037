
import { defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import IndicalForm from './IncidalForm.vue';
import SettleForm from './SettleForm.vue';
import { erpConfirm, errorTip, successTip } from '@/utils/publicMethods';
import { httpGet, httpPost } from '@/api';
export default defineComponent({
  name: 'statements',
  components: { ErpList, tableFileContent, IndicalForm, SettleForm },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const confirmDialog = reactive({
      isShow: false,
      title: '',
    });
    const tableFileUpLoad = ref('');
    const incidalDialog = reactive({
      visible: false,
      btnTitle: '',
    });
    const formData = reactive({
      isNeedCustomerSign: false,
      confirmDesc: '',
    });

    const data = reactive({
      currentRow: null,
      rowData: null,
      load: false,
    });
    const customParams: any = ref();
    const formType = ref(1);

    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };

    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    // 更新结算单
    const updateFn = (row) => {
      erpConfirm('确定要更新结算单吗？').then(async () => {
        const res = await httpPost('/malicrm/fmSellStmt/updateFmSellStmtList', {
          sellStmtId: row.id,
          version: row.version,
        });
        if (res.code === 200) {
          refreshTable(true);
        } else {
          errorTip(res.message);
        }
      });
    };
    // 作废
    const toVoid = async (row) => {
      const res = await httpGet(`/malicrm/fmSellStmt/invalidSellStmt/${row.id}`);
      if (res.code === 200) {
        successTip('结算单已作废', 3000);
        refreshTable(true);
      } else {
        errorTip(res.message);
      }
    };
    // 上传文件
    const uploadFn = (row) => {
      data.currentRow = row;
      data.load = false;
      setTimeout(() => {
        data.load = true;
      }, 10);
      setTimeout(() => {
        (tableFileUpLoad.value as any).uploadVisable = true;
      }, 100);
    };
    const getUploadFiles = async ({ data: FileData }) => {
      const currentRow = data.currentRow as any;
      const res = await httpPost('/malicrm/fmSellStmt/uploadFmSellStmtList', {
        excelUrl: FileData[0].fmFileUrl,
        sellStmtId: currentRow.id,
        version: currentRow.version,
      });
      if (res.code === 200) {
        successTip('结算单已更新', 3000);
        refreshTable(true);
      } else {
        errorTip(res.message);
      }
    };
    // 确认
    const confirmFn = (row) => {
      formData.isNeedCustomerSign = false;
      formData.confirmDesc = '';
      data.rowData = row;
      confirmDialog.isShow = true;
    };
    // 确认操作
    const dialogCallBack = async (type) => {
      if (type === '确定') {
        // 向后台发送请求
        const rowData = data.rowData as any;
        const { id, version } = rowData;
        const param = {
          id,
          version,
          isNeedCustomerSign: formData.isNeedCustomerSign,
          confirmDesc: formData.confirmDesc,
        };
        if (!rowData.hasEqbAccount) {
          param.isNeedCustomerSign = false;
        }
        const res = await httpPost('/malicrm/fmSellStmt/confirmStmt', param);
        if (res.code === 200) {
          refreshTable(true);
        } else {
          errorTip(res.message);
        }
      }
      confirmDialog.isShow = false;
    };
    const getIndicalFormCallback = (val) => {
      incidalDialog.visible = false;
      refreshTable(val);
    };
    // 杂费结算
    const incidentalFn = () => {
      incidalDialog.btnTitle = '生成结算单';
      data.currentRow = {} as any;
      incidalDialog.visible = true;
    };

    // 编辑
    const editFn = (row) => {
      data.currentRow = row;
      incidalDialog.btnTitle = '更新结算单';
      incidalDialog.visible = true;
    };
    return {
      incidentalFn,
      getIndicalFormCallback,
      incidalDialog,
      editFn,
      getUploadFiles,
      tableFileUpLoad,
      dialogCallBack,
      confirmFn,
      confirmDialog,
      formData,
      uploadFn,
      toVoid,
      data,
      ...toRefs(data),
      updateFn,
      formType,
      cancel,
      closeVisible,
      customParams,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
    };
  },
});
