<template>
  <div class="settle-form-dialog">
    <div class="settle-tips">
      <span class="icon maliiconfont maliicon-img_details_report1"></span>
      <span class="tips-text"
        >提空后结算用于货物提空之后，和客户结算多退少补的费用
        例如：最终结算。</span
      >
    </div>
    <el-form
      ref="form"
      :model="formData"
      label-width="98px"
      :rules="rules"
      class="settle-dialog-form"
    >
      <el-form-item label="销售合同号" prop="businessContractNo">
        <el-input
          v-model.trim="formData.businessContractNo"
          clearable
          :maxlength="50"
          style="width: 268px"
          class="mali-full__inputcom"
          placeholder="请填写销售合同号"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn-box">
      <el-button size="small" type="primary" @click="submit"
        >生成结算单</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: { businessContractNo: '' },
      rules: {
        businessContractNo: [
          {
            type: 'string',
            required: true,
            message: '请填写销售合同号',
            trigger: 'blur',
          },
        ],
      },
    };
  },

  methods: {
    submit() {
      // 提交动作
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveSubmitForm();
        }
      });
    },
    saveSubmitForm() {
      const businessContractNo = this.formData.businessContractNo;
      this.ajax({
        type: 'GET',
        url: '/malicrm/fmSellStmt/setFinalStmt/' + businessContractNo,
        success: (res) => {
          if (res.code === 200) {
            this.successTip('已生成结算单');
            this.$emit('cancel', true); // 通知列表页面关闭弹窗    true刷新列表  false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.settle-form-dialog {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
  .mali-full__inputcom {
    color: #1a1a1a;
  }
  .settle-tips {
    display: flex;
    // margin-top: 15px;
    justify-content: flex-start;
    margin-bottom: 35px;
    // align-items: center;
    .icon {
      flex-basis: 32px;
      font-size: 19px;
      color: #c7c8cd;
    }
    .tips-text {
      line-height: 24px;
      margin-top: -2px;
      color: #909399;
      font-size: 14px;
      font-family: '微软雅黑';
    }
  }
  .btn-box {
    text-align: center;
    margin-top: 50px;
  }
}
</style>
